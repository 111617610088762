import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { Item } from "../../pojo/Item";
import { Event } from "../../pojo/Event";
import { EventStateChangeService, EventStateConst } from "../../services/event.state.change.service";
import { AccessLevel, AccessLevels, Fls } from "../../pojo/Fls";
import { S25LoadingApi } from "../s25-loading/loading.api";
import { ModalService } from "../modal/modal.service";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { PreferenceService } from "../../services/preference.service";
import { UserprefService } from "../../services/userpref.service";
import { EventService } from "../../services/event.service";
import { LangService } from "../../services/lang.service";
import { FlsService } from "../../services/fls.service";
import { OlsService } from "../../services/ols.service";
import { CabinetSelectModal } from "../modal/modals/modal.cabinet.select.component";
import { ReservationService } from "../../services/reservation.service";

// Alias typescript enum Event.State.Ids
import StateId = Event.State.Ids;
import { jSith } from "../../util/jquery-replacement";
import { AlertService } from "../../services/alert.service";

@TypeManagerDecorator("s25-ng-event-states")
@Component({
    selector: "s25-ng-event-states",
    template: `
        <div class="c-inputItem__control">
            <s25-loading-inline [model]="{}"></s25-loading-inline>
            <div *ngIf="isInit && canChangeState">
                <select
                    [(ngModel)]="chosenStateId"
                    (ngModelChange)="changeState($event)"
                    class="c-selectInput"
                    aria-label="Select State"
                >
                    <option
                        *ngFor="let state of states"
                        [hidden]="!state.isValidTransition"
                        [ngValue]="state.id"
                        [selected]="state.id === chosenStateId"
                    >
                        {{ state.name }}
                    </option>
                </select>

                <button
                    *ngIf="allowSetDefault && canSetAsDefault"
                    (click)="setDefault()"
                    class="aw-button aw-button--primary"
                >
                    Set Default
                </button>
            </div>
            <span *ngIf="isInit && !canChangeState && alwaysShowStateName">{{ chosenState.name }}</span>
        </div>
    `,
    styles: `
        :host {
            display: inline-block !important;
        }
    `,
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25EventStatesComponent implements OnInit {
    @Input() itemId: Item.Id;
    @Input() eventState?: Event.State.Id;
    @Input() alwaysShowStateName?: boolean = false;
    @Input() blacklistedStates?: Event.State.Id[] = [];
    @Input() allowSetDefault?: boolean = false;
    @Input() onChangeHandler?: (id: Event.State.Id) => Promise<void>;
    isInit: boolean;
    canChangeState: boolean = false;
    flsEventState: AccessLevel;
    chosenState: Event.State.Data;
    chosenStateId: Event.State.Id;
    previousStateId: Event.State.Id = null;
    defaultStateId: Event.State.Id;
    states: Event.State.Data[];
    stateLang: any;
    canCreateTodos: boolean;
    canSetAsDefault: boolean = false;

    constructor(
        private elementRef: ElementRef,
        private changeDetector: ChangeDetectorRef,
    ) {}

    async ngOnInit() {
        window.addEventListener(EventStateConst.updateState, (event) => this.onUpdateEventState(event));

        if (!(await UserprefService.getLoggedIn())) return;
        const { eventState, isExpress, isRequester, ols, lang, fls, preferences, allowedStates } = await this.getData();

        this.stateLang = lang.div.controls["s25-rsrv_wiz"].text.state;
        this.eventState = eventState;
        this.flsEventState = fls?.EVENT_STATE;
        this.defaultStateId = parseInt(preferences?.SpbkEvState?.value ?? "1");
        this.canCreateTodos = fls && fls.CREATE_TODO !== AccessLevels.None;

        // Express state change can happen even if user lacks a lot of perms so long as the event is express and the user is the requester
        //ANG-4349 express events do not allow expressChangeOverride for events that have already started
        const expressStateChangeOverride = isExpress && isRequester;
        if (expressStateChangeOverride && !allowedStates.includes(StateId.Cancelled)) {
            const evOcc = await ReservationService.getEventReservations(this.itemId);
            if (evOcc?.length > 0 && new Date() < S25Util.date.parse(evOcc[0].reservation_start_dt))
                allowedStates.push(StateId.Cancelled);
        }

        const filteredStates = this.filterAllowedStates(allowedStates, this.canEdit(fls?.EVENT_DRAFT), preferences);
        this.canChangeState = this.getCanChangeState(filteredStates, fls, ols, expressStateChangeOverride);

        // Always show the initial event state even if the user can't technically transition to that state
        // and if they transition away, see "changeState" function where we remove the initial event state
        // as a choice in that case (eg, when the user has no perms to transition back, ie, the state "isOnlyForDisplay")
        const statesToShow = S25Util.array.unique(filteredStates.concat(this.eventState));
        this.states = statesToShow.map(this.stateIdObjGenerator(filteredStates));
        this.setStateById(this.eventState);
        this.setTransitions();
        this.previousStateId = this.eventState;
        this.isInit = true;
        this.changeDetector.detectChanges();
    }

    getData() {
        return Promise.all([
            this.eventState ?? (this.itemId && EventService.getEventState(this.itemId)),
            this.itemId && EventService.isExpress(this.itemId),
            this.itemId && EventService.isCurrentRequester(this.itemId),
            this.itemId && OlsService.getOls([this.itemId], Item.Ids.Event, "edit"),
            LangService.getLang(),
            FlsService.getFls(),
            PreferenceService.getPreferences(["config_BPE_event_get", "SpbkEvState"]),
            UserprefService.getAllowedStates(),
        ]).then(([eventState, isExpress, isRequester, ols, lang, fls, preferences, allowedStates]) => {
            return { eventState, isExpress, isRequester, ols, lang, fls, preferences, allowedStates };
        });
    }

    getCanChangeState(allowedStates: Event.State.Id[], fls: Fls, ols: any, override: boolean) {
        const flsCanAccessRose = fls?.SPEEDBOOK === AccessLevels.Full;
        const flsCanEditState = this.canEdit(fls?.EVENT_STATE);
        const flsCanEditDraft = this.canEdit(fls?.EVENT_DRAFT);
        const flsCanEditEvents = this.canEdit(fls?.EVENT_EVS);
        const stateAllowed = allowedStates.includes(this.eventState);
        const isDraft = this.eventState === StateId.Draft;
        const olsAccess = this.canEdit(ols?.[0]?.access_level || AccessLevels.Full);
        // User must have rights to the event form, state change, the current state in wizard security, event editing, and OLS
        // however, the user can skip everything EXCEPT OLS if they have express override for state change only
        if (olsAccess && override) return true;
        else if (olsAccess && flsCanAccessRose && flsCanEditState && stateAllowed)
            return isDraft ? flsCanEditDraft : flsCanEditEvents;
        else return false;
    }

    filterAllowedStates(allowedEventStates: Event.State.Id[], canEditDraft: boolean, preferences: any) {
        const bpeGet = preferences?.config_BPE_event_get?.value;
        let allowedStates = bpeGet === "configurations" ? allowedEventStates : EventStateConst.allEventStates;
        return allowedStates.filter((state) => {
            // If state is draft make sure user can edit drafts
            if (state === StateId.Draft && !canEditDraft) return false;
            // Denied and Cancelled can only be edited with EVENT_STATE === F
            if (
                [StateId.Denied, StateId.Cancelled].includes(this.eventState) &&
                this.flsEventState !== AccessLevels.Full
            )
                return false;
            // Remove blacklisted states
            if (this.blacklistedStates.includes(state)) return false;
            // Keep the rest
            return true;
        });
    }

    stateIdObjGenerator(allowedStates: Event.State.Id[]) {
        return function (id: Event.State.Id) {
            return {
                name: EventStateConst.stateMap[id] as Event.State.Name,
                id,
                confirm: [StateId.Sealed, StateId.Denied, StateId.Cancelled].includes(id), // Flag set to trigger confirmation warning text to user
                isValidTransition: true,
                isOnlyForDisplay: !allowedStates.includes(id), // Actual event state NOT allowed transition for this user
            } as Event.State.Data;
        };
    }

    canEdit(access: AccessLevel) {
        return access === AccessLevels.Edit || access === AccessLevels.Full;
    }

    setStateById(id: Event.State.Id) {
        this.chosenState = this.states.find((state) => state.id === id) ?? this.chosenState;
        this.chosenStateId = this.chosenState.id;
        this.updateCanSetAsDefault();
        this.changeDetector.detectChanges();
    }

    setTransitions() {
        if (!this.chosenState) return;
        for (let state of this.states) {
            if (!EventStateConst.transitionMap[this.chosenState.id][state.id]) continue;
            state.isValidTransition = false;
            switch (this.chosenState.id) {
                case state.id: // Always show the selected state
                case StateId.Draft:
                case StateId.Tentative:
                case StateId.Confirmed:
                case StateId.Sealed:
                    state.isValidTransition = true;
                    break;
                case StateId.Denied:
                case StateId.Cancelled:
                    if (this.flsEventState === AccessLevels.Full) state.isValidTransition = true;
                    break;
            }
        }
        this.changeDetector.detectChanges();
    }

    postChangeSuccess(id: Event.State.Id) {
        this.previousStateId = id;
        // If state user transitioned away from was only for display, that means
        // the user has NO permissions to transition back to that state, so we
        // remove it from the list of states the user can choose
        this.states = this.states.filter((state) => !state.isOnlyForDisplay);
        this.setTransitions();
    }

    async postChangeFailure(id: Event.State.Id, error: any) {
        S25LoadingApi.destroy(this.elementRef.nativeElement);
        this.setStateById(this.previousStateId);
        if (error?.response === "multipleParents") {
            const data: CabinetSelectModal = {
                parent: error.data,
                onDone: (parentId: number) => this.changeState(id, true, parentId),
            };
            await ModalService.modal("cabinet-select", data);
        } else if (this.canCreateTodos && error?.results?.error?.msg_id?.includes("EV_E_NOPERM")) {
            ModalService.modal("cancel-request", { itemId: this.itemId });
        } else {
            S25Util.showError(error);
        }
    }

    changeState(id: Event.State.Id, skipWarning?: boolean, parentId?: Event.State.Id) {
        this.setStateById(id);
        // If there is an onChangeHandler call it, otherwise use default handling
        if (this.onChangeHandler) {
            if (!this.chosenState || id === this.previousStateId) return; // Only call callback if state has changed
            this.onChangeHandler(id).then(
                () => this.postChangeSuccess(id),
                (error) => this.postChangeFailure(id, error),
            );
        } else {
            let confirm = jSith.when(true);
            if (this.chosenState?.confirm && !skipWarning) {
                confirm = AlertService.confirm(this.stateLang[EventStateConst.warningMap[this.chosenState.id]]);
            }

            confirm.then((isConfirmed) => {
                if (isConfirmed && this.chosenState && id !== this.previousStateId)
                    this.changeStateDataCall(id, parentId);
                else this.setStateById(this.previousStateId);
            });
        }
    }

    async changeStateDataCall(id: Event.State.Id, parentId?: Event.State.Id) {
        // Attempt to change state
        S25LoadingApi.init(this.elementRef.nativeElement);
        let response: any;
        try {
            response = await EventStateChangeService.changeState(this.itemId, id, parentId);
        } catch (error: any) {
            return this.postChangeFailure(id, error);
        }
        S25LoadingApi.destroy(this.elementRef.nativeElement);

        // If change was successful then return
        if (response?.response === "success") return this.postChangeSuccess(id);

        // Change was not successful, revert to previous state
        this.setStateById(this.previousStateId);

        // If there are conflicts show them to the user
        if (response?.response !== "conflicts") return;
        let conflicts: any[] = [];
        for (let reservation of S25Util.array.forceArray(response.data) as any[]) {
            for (let space of S25Util.array.forceArray(reservation.space_reservation) as any[]) {
                conflicts.push(space.conflicts);
            }
            for (let resource of S25Util.array.forceArray(reservation.resource_reservation) as any[]) {
                conflicts.push(resource.conflicts);
            }
        }
        conflicts = S25Util.array.uniqueDeep(conflicts);
        ModalService.modal("draft-conflicts", { conflicts });
    }

    updateCanSetAsDefault() {
        this.canSetAsDefault = ![this.defaultStateId, StateId.Sealed, StateId.Denied, StateId.Cancelled].includes(
            this.chosenState.id,
        );
    }

    setDefault() {
        return PreferenceService.setPreference("SpbkEvState", this.chosenState.id).then(
            () => {
                this.defaultStateId = this.chosenState.id;
                this.updateCanSetAsDefault();
                this.changeDetector.detectChanges();
            },
            (error) => S25Util.showError(error),
        );
    }

    onUpdateEventState(event: any) {
        let data = event.detail;
        data.newStateId = parseInt(data.newStateId);
        if (parseInt(data.eventId) === this.itemId && data.newStateId !== this.chosenState?.id) {
            this.previousStateId = data.newStateId;
            this.setStateById(data.newStateId);
            this.setTransitions();
        }
    }
}
