//@author: devin
//Migrated by: travis

import { jSith } from "../../../util/jquery-replacement";
import { S25Util } from "../../../util/s25-util";
import { EventFormConflictRepoService } from "./s25.event.form.conflict.repo.service";
import { ObjectSearchAvailabilityUtil } from "../../../util/object.search.availability.util";
import { EventQuotaService } from "../../../services/event.quota.service";
import { S25ItemI } from "../../../pojo/S25ItemI";
import { Event } from "../../../pojo/Event";
import { UserprefService } from "../../../services/userpref.service";
import { S25Datefilter } from "../../s25-dateformat/s25.datefilter.service";

export class EventFormOccurrenceUtil {
    public static getStartDtFromDtMin(evStartDt: Date, minutes: any) {
        return S25Util.date.addMinutes(evStartDt, -1 * ((minutes.setupMinutes || 0) + (minutes.preMinutes || 0)));
    }

    public static getStartDt(occ: Event.Occurrence) {
        return EventFormOccurrenceUtil.getStartDtFromDtMin(occ.evStartDt, occ.minutes);
    }

    public static getEndDtFromDtMin(evEndDt: Date, minutes: any) {
        return S25Util.date.addMinutes(evEndDt, (minutes.postMinutes || 0) + (minutes.takedownMinutes || 0));
    }

    public static getEndDt(occ: Event.Occurrence) {
        return EventFormOccurrenceUtil.getEndDtFromDtMin(occ.evEndDt, occ.minutes);
    }

    public static getPreDt(occ: Event.Occurrence) {
        return S25Util.date.addMinutes(EventFormOccurrenceUtil.getStartDt(occ), occ.minutes.setupMinutes || 0);
    }

    public static getPostDt(occ: Event.Occurrence) {
        return S25Util.date.addMinutes(occ.evEndDt, occ.minutes.postMinutes || 0);
    }

    public static spansMidnight(occ: Event.Occurrence) {
        return S25Util.date.getDate(occ.evEndDt) > S25Util.date.getDate(occ.evStartDt);
    }

    public static forEachOccOnDate(
        occurrences: any,
        date: Date,
        exitEarly: any,
        func: (occ: Event.Occurrence) => any,
        isDatePicker?: boolean,
    ) {
        const compareFn = isDatePicker ? S25Util.date.isBetweenEqual : S25Util.date.isBetween;
        for (let i = 0; i < occurrences.length; i++) {
            let occ = occurrences[i];
            //if null date, add all...
            if (
                !date ||
                compareFn(
                    date,
                    isDatePicker
                        ? S25Util.date.getDate(EventFormOccurrenceUtil.getStartDt(occ))
                        : EventFormOccurrenceUtil.getStartDt(occ),
                    isDatePicker
                        ? S25Util.date.toEndOfDay(EventFormOccurrenceUtil.getEndDt(occ))
                        : EventFormOccurrenceUtil.getEndDt(occ),
                )
            ) {
                let resp = func(occ);
                if (resp && exitEarly) {
                    return resp;
                }
            }
        }
    }

    public static hasOccOnDate(occurrences: any, date: Date, isDatePicker?: boolean) {
        return !!EventFormOccurrenceUtil.forEachOccOnDate(
            occurrences,
            date,
            true,
            function () {
                return true;
            },
            isDatePicker,
        );
    }

    public static getOccOnDate(occurrences: any, date: Date, isDatePicker?: boolean) {
        let ret: any = [];
        EventFormOccurrenceUtil.forEachOccOnDate(
            occurrences,
            date,
            false,
            function (occ) {
                ret.push(occ);
            },
            isDatePicker,
        );
        ret.sort(S25Util.shallowSortDates("evStartDt", "evEndDt")); //sort by start, then end
        return ret;
    }

    public static getOccObj(
        formModel: Event.Form,
        occ: Event.Occurrence,
        onlyIncluded: any,
        type?: "locations" | "resources",
    ): any {
        //type: "locations", "resources"
        if (!type) {
            return [].concat(
                EventFormOccurrenceUtil.getOccLocations(formModel, occ, onlyIncluded),
                EventFormOccurrenceUtil.getOccResources(formModel, occ, onlyIncluded),
            );
        }
        let objects: any = [];
        jSith.forEach(formModel[type], function (key_, obj) {
            let objOcc = formModel.getObjOcc(obj, occ, true);
            if (objOcc) {
                if ((onlyIncluded && objOcc.isIncluded) || !onlyIncluded) {
                    objects.push(obj);
                }
            }
        });
        return objects;
    }

    public static getOccLocations(formModel: Event.Form, occ: Event.Occurrence, onlyIncluded: any) {
        return EventFormOccurrenceUtil.getOccObj(formModel, occ, onlyIncluded, "locations");
    }

    public static getOccResources(formModel: Event.Form, occ: Event.Occurrence, onlyIncluded: any) {
        return EventFormOccurrenceUtil.getOccObj(formModel, occ, onlyIncluded, "resources");
    }

    public static getAllObjOcc(formModel: Event.Form, onlyIncluded: any, type?: "locations" | "resources") {
        let all: any = [];
        jSith.forEach(formModel.occurrences, function (key, occ) {
            all = all.concat(EventFormOccurrenceUtil.getOccObj(formModel, occ, onlyIncluded, type));
        });
        return all;
    }

    public static getAllLocationOcc(formModel: Event.Form, onlyIncluded: any) {
        return EventFormOccurrenceUtil.getAllObjOcc(formModel, onlyIncluded, "locations");
    }

    public static getAllResourceOcc(formModel: Event.Form, onlyIncluded: any) {
        return EventFormOccurrenceUtil.getAllObjOcc(formModel, onlyIncluded, "resources");
    }

    public static getOccMissingObj(formModel: Event.Form, type: "locations" | "resources") {
        //type: locations | resources
        let occs: any = [];
        jSith.forEach(formModel.occurrences, function (key, occ) {
            if (
                occ.state &&
                [99, 2].indexOf(occ.state.itemId) === -1 &&
                EventFormOccurrenceUtil.getOccObj(formModel, occ, true, type).length === 0
            ) {
                occs.push(occ);
            }
        });
        return occs;
    }

    public static checkQuotas(formModel: Event.Form, locations: S25ItemI[]) {
        //Start by removing any existing quotas.
        S25Util.propertyDelete(formModel.occurrences, "hasQuotaConflict");
        S25Util.propertyDelete(formModel.occurrences, "quotaConflict");

        let primaryOrgId =
            formModel.itemDict &&
            formModel.itemDict.primaryOrg &&
            formModel.itemDict.primaryOrg.data &&
            formModel.itemDict.primaryOrg.data.itemId;

        let activeOccurences =
            S25Util.array.forceArray(formModel.occurrences) &&
            formModel.occurrences.filter((occ: Event.Occurrence) => {
                return occ.state && occ.state.itemId === 1;
            });
        let state = formModel.itemDict && formModel.itemDict.state && formModel.itemDict.state.data;
        if (S25Util.isDefined(locations)) {
            locations = S25Util.array
                .forceArray(locations)
                .filter((loc: S25ItemI) => loc.itemTypeId === 4)
                .map((id: S25ItemI) => {
                    return { itemId: id.itemId, itemName: id.itemName };
                });
        } else {
            locations =
                formModel
                    .getLocOccs()
                    .filter((occ: Event.Occurrence) => occ.isIncluded)
                    .map((obj: Event.Occurrence) => {
                        return {
                            itemId: obj.item.itemId,
                            occ_id: obj.occ.uuid,
                            itemName: obj.item.itemName,
                            itemTypeId: 4,
                        };
                    }) || [];
        }

        if ([0, 98, 99].indexOf(state) > -1) {
            //Do not check draft, denied, cancelled events - or events without a state yet
            return jSith.when([]);
        } else {
            return EventQuotaService.check(activeOccurences, locations, primaryOrgId).then((quota) => {
                quota.forEach((q: any) => {
                    let occ = S25Util.array.getByProp(activeOccurences, "uuid", q.occ.uuid);
                    if (q.occ && !!q.occ.object_id) {
                        let loc = S25Util.array.getByProp(formModel.locations, "itemId", q.quota.quota_id);
                        q.occ.location = loc;
                        if (loc && loc.checkedObj) loc.checkedObj.hasQuotaConflict = true;
                    }

                    occ.hasQuotaConflict = true;
                    occ.quotaConflict = q;
                });
                return quota;
            });
        }
    }

    public static checkAvailability(
        formModel: Event.Form,
        items: any,
        perItemFunc: (item: any) => any,
        specificOccs: any,
    ) {
        let thisPerItemFunc = function (item: any) {
            (perItemFunc || jSith.noop)(item);

            if (specificOccs) {
                jSith.forEach(specificOccs, function (key, occ) {
                    EventFormConflictRepoService.removeConflictByObjOccModel(formModel, item, occ);
                });
            } else {
                EventFormConflictRepoService.removeConflictByObjectModel(formModel, item);
            }

            if (item.checkedObj.hasRealConflicts) {
                jSith.forEach(item.checkedObj.conflicts, function (key, conflict) {
                    conflict.isReal && EventFormConflictRepoService.addConflictByModel(formModel, conflict);
                });
            }
        };

        let reservations: any = [];
        jSith.forEach(specificOccs || formModel.occurrences, function (key, occ) {
            if ([99, 2].indexOf(occ.state.itemId) === -1) {
                //no cancelled or excluded
                let rsrv: any = {
                    occUUID: occ.uuid,
                    reservation_start_dt: EventFormOccurrenceUtil.getStartDt(occ),
                    reservation_end_dt: EventFormOccurrenceUtil.getEndDt(occ),
                    space_reservation: [],
                    resource_reservation: [],
                };

                reservations.push(rsrv);

                jSith.forEach(items, function (key, item) {
                    let objOcc = formModel.getObjOcc(item, occ, true);

                    if (objOcc) {
                        if (objOcc.isIncluded && item.itemTypeId === 4) {
                            rsrv.space_reservation.push({
                                space_id: item.itemId,
                                share: objOcc.spaceShare ? "T" : "F",
                                always_shared: item.always_shared,
                            });
                        } else if (item.itemTypeId === 6) {
                            //note: we still call avail for UNincluded resources to get quantity available
                            rsrv.resource_reservation.push({
                                resource_id: item.itemId,
                                quantity: objOcc.resourceQuantity,
                                isIncluded: objOcc.isIncluded,
                            });
                        }
                    }
                });
            }
        });

        return ObjectSearchAvailabilityUtil.checkAvailability(
            formModel.eventId,
            formModel.profileId,
            reservations,
            items,
            thisPerItemFunc,
            specificOccs,
        );
    }

    public static checkExceedingResourceQuantity<R extends { checkedObj: any; itemId: number }>(
        resource: R,
        occurrences: Event.Occurrence[],
    ) {
        resource.checkedObj.exceedingQuantity = [];

        // Get item occurrences
        let occs = occurrences
            .filter((occ) => occ.item.itemTypeId === 6 && occ.item.itemId === resource.itemId)
            .sort((a, b) => a.occ.evStartDt - b.occ.evStartDt);

        // Find any overlap
        for (let i = 0; i < occs.length; i++) {
            const occ = occs[i];
            const start = EventFormOccurrenceUtil.getStartDtFromDtMin(occ.occ.evStartDt, occ.occ.minutes);
            const end = EventFormOccurrenceUtil.getEndDtFromDtMin(occ.occ.evEndDt, occ.occ.minutes);
            let reserved = occ.resourceQuantity;
            const overlapping: Event.Occurrence[] = [occ];
            for (let j = i + 1; j < occs.length; j++) {
                const occ2 = occs[j];
                const start2 = EventFormOccurrenceUtil.getStartDtFromDtMin(occ2.occ.evStartDt, occ2.occ.minutes);
                if (start2 < end) {
                    reserved += occ2.resourceQuantity; // Overlaps
                    overlapping.push(occ2);
                } else break; // No more overlap, move on to next item
            }
            if (reserved > occ.maxQuantity) {
                resource.checkedObj.exceedingQuantity.push({
                    start,
                    end,
                    occIds: new Set(overlapping.map((occ) => occ.occ.uuid)),
                    occs: overlapping,
                    reserved,
                    max: occ.maxQuantity,
                });
            }
        }
    }

    public static mergePrefOccsIntoProfile(
        eventData: any,
        profile: any,
        prefFlag: any,
        onSpMergeF: any,
        onAllEventStates: any,
        newObjOccStatus?: string,
    ) {
        newObjOccStatus = newObjOccStatus || "new";
        let eventText = eventData.event_text;
        let rsrvPrefs = S25Util.propertyGetParentWithChildValue(eventText, "text_type_id", 9);
        let prefJson = rsrvPrefs && rsrvPrefs.text && S25Util.parseSpaceResourcePreferences(rsrvPrefs.text);

        //if event is draft, add loc/res preferences as real ones again (ANG-1751)
        if (prefJson && (parseInt(eventData.state) === 0 || onAllEventStates)) {
            if (prefJson && prefJson.reservation_list) {
                jSith.forEach(prefJson.reservation_list.reservation, function (key, prefRsrv) {
                    let rsrv = S25Util.propertyGetParentWithChildValue(
                        profile.reservation,
                        "reservation_id",
                        prefRsrv.reservation_id,
                    );
                    if (rsrv) {
                        if (!rsrv.space_reservation) {
                            rsrv.space_reservation = [];
                        }

                        if (!rsrv.resource_reservation) {
                            rsrv.resource_reservation = [];
                        }

                        jSith.forEach(prefRsrv.space_reservation, function (key, spRsrv) {
                            if (
                                spRsrv.space_id > 0 &&
                                !S25Util.valueFind(rsrv.space_reservation, "space_id", spRsrv.space_id)
                            ) {
                                onSpMergeF && onSpMergeF();

                                let newSpRsrv: any = {
                                    status: newObjOccStatus,
                                    space_id: spRsrv.space_id,
                                    space_instructions: spRsrv.space_instructions,
                                    share: spRsrv.share,
                                    attendance: spRsrv.attendance,
                                    layout_id: spRsrv.layout_id,
                                    layout_name: null,
                                    space: {
                                        space_name: spRsrv.space_name + "",
                                        formal_name: null,
                                        max_capacity: null,
                                    },
                                };

                                if (prefFlag) {
                                    newSpRsrv.isPreference = true;
                                }

                                if (newObjOccStatus !== "est" && profile.status === "est") {
                                    profile.status = "mod";
                                }
                                rsrv.space_reservation.push(newSpRsrv);
                            }
                        });

                        jSith.forEach(prefRsrv.resource_reservation, function (key, rsRsrv) {
                            if (
                                rsRsrv.resource_id > 0 &&
                                !S25Util.valueFind(rsrv.resource_reservation, "resource_id", rsRsrv.resource_id)
                            ) {
                                let newRsRsrv: any = {
                                    status: newObjOccStatus,
                                    resource_id: rsRsrv.resource_id,
                                    quantity: rsRsrv.quantity,
                                    resource_instructions: rsRsrv.resource_instructions,
                                    resource: {
                                        resource_name: rsRsrv.resource_name + "",
                                    },
                                };

                                if (prefFlag) {
                                    newRsRsrv.isPreference = true;
                                }

                                if (newObjOccStatus !== "est" && profile.status === "est") {
                                    profile.status = "mod";
                                }
                                rsrv.resource_reservation.push(newRsRsrv);
                            }
                        });
                    }
                });
            }
        }

        return (prefJson && prefJson.reservation_list && prefJson.reservation_list.reservation) || [];
    }
}
