import { Rule, RuleItem, RuleItems, RuleValue } from "../../services/rule.tree.service";
import { S25RuleTreeUtil } from "../s25-rule-tree/s25.rule.tree.util";
import { CustomAttribute } from "../s25-custom-attribute/s25.custom.attribute.item.formatter";
import { S25Util } from "../../util/s25-util";
import { Rules } from "../s25-rule-tree/s25.rule.const";
import { Proto } from "../../pojo/Proto";
import ISODateString = Proto.ISODateString;
import { EventSummary } from "../s25-swarm-schedule/s25.event.summary.service";
import NumericalBoolean = Proto.NumericalBoolean;
import { SearchService } from "../../services/search/search.service";
import { Item } from "../../pojo/Item";

export namespace EventFormRuleUtil {
    import DowChar = EventSummary.DowChar;

    export function getSatisfiedRules(data: Omit<RuleData, "rule"> & { rules: Rule[] }) {
        const active = S25Util.array.forceArray(data.rules).filter((rule) => rule.active);
        const { sync, async } = S25Util.array.groupBy(active, (rule) => (isAsync(rule.item) ? "async" : "sync"));

        const syncSatisfied = S25Util.array
            .forceArray(sync)
            .filter((rule) => satisfyRule({ ...data, rule: rule.item }));
        const asyncSatisfied = getSatisfiedAsyncRules({ ...data, rules: S25Util.array.forceArray(async) });

        return { sync: syncSatisfied, async: asyncSatisfied };
    }

    export async function getSatisfiedAsyncRules(data: Omit<RuleData, "rule"> & { rules: Rule[] }) {
        const promises: Promise<void>[] = [];
        const asyncData: AsyncRuleData = {
            search: {},
        };
        for (const rule of data.rules) getAsyncData(data, rule.item, promises, asyncData);
        await Promise.allSettled(promises);

        return data.rules.filter((rule) => satisfyRule({ ...data, rule: rule.item }, asyncData));
    }

    export function getAsyncData(
        data: Omit<RuleData, "rule">,
        rule: RuleItems | RuleItem,
        promises: Promise<void>[],
        asyncData: AsyncRuleData,
    ): Promise<void>[] {
        if ("sourceItem" in rule && Rules.asyncTypes.has(rule.sourceItem.itemTypeId)) {
            const valueType = Rules.typeIdToType[rule.sourceItem.itemTypeId].valueType;
            if (valueType.type === "search") {
                getAsyncSearchData(data, rule.val_obj, valueType.itemType, promises, asyncData);
            }
        }
        if (rule.children === "") return promises;

        for (const child of rule.children.item) getAsyncData(data, child, promises, asyncData);
        return promises;
    }

    export function getAsyncSearchData(
        data: Omit<RuleData, "rule">,
        ruleValues: RuleValue[],
        itemType: Item.Ids,
        promises: Promise<void>[],
        asyncData: AsyncRuleData,
    ) {
        let ids: number[] = [];
        switch (itemType) {
            case Item.Ids.Location:
                ids = data.locations.map((loc) => loc.itemId);
                break;
            case Item.Ids.Resource:
                ids = data.resources.map((loc) => loc.itemId);
                break;
            case Item.Ids.Organization:
                ids = [data.primaryOrg.itemId];
                break;
        }

        for (const query of ruleValues) {
            const queryId = Number(query.value);
            const promise = SearchService.doesSearchIncludeItems(itemType, queryId, ids);
            promises.push(promise.then()); // .then() to be Promise<void>

            asyncData.search[itemType] ??= {};
            asyncData.search[itemType][queryId] ??= {};
            promise.then((data) => Object.assign(asyncData.search[itemType][queryId], data));
        }
    }

    export function isAsync(rule: RuleItems | RuleItem) {
        if ("sourceItem" in rule) {
            if (Rules.asyncTypes.has(rule.sourceItem.itemTypeId)) return true;
        }
        if (rule.children === "") return false;
        return rule.children.item.some(isAsync);
    }

    export function satisfyRule(data: RuleData, asyncData?: AsyncRuleData): boolean {
        if (data.rule.type !== "bool") {
            return satisfyComparison(data as RuleData<RuleItem>, asyncData);
        }

        const { rule } = data;
        let satisfied = rule.operator === "and"; // if 'and', default true so 'and' is not always false; if 'or' default false so 'or' is not always true
        for (let i = 0; i < (rule.children.item && rule.children.item.length); i++) {
            const child = rule.children.item[i];
            const childSatisfied = satisfyRule({ ...data, rule: child }, asyncData);
            if (rule.operator === "and") {
                satisfied = satisfied && childSatisfied;
                if (!satisfied) return satisfied;
            } else {
                satisfied = satisfied || childSatisfied;
                if (satisfied) return satisfied;
            }
        }
        return satisfied;
    }

    export function satisfyComparison(data: RuleData<RuleItem>, asyncData?: AsyncRuleData) {
        const { rule } = data;
        const sourceTypeId = Number(data.rule.sourceItem.itemTypeId);

        const isEventState = sourceTypeId === Rules.type.EventState.id;
        const triggerOnlyIfChanged = rule.sourceItem.itemId === Rules.ifChanged.Yes.itemId;
        const eventStateChanged = data.eventState !== data.eventStateOriginal;
        if (isEventState && triggerOnlyIfChanged && !eventStateChanged) {
            return false;
        }

        const dataAndType = getValue(data, asyncData);
        if (S25Util.isUndefined(dataAndType) || !rule.val_obj) return false; // Invalid condition
        const text = dataAndType.value as string;
        const number = dataAndType.value as number;
        const bool = dataAndType.value as boolean;

        const values = rule.val_obj.map((val) => val.value);
        switch (dataAndType.type) {
            case "multiselect":
                const intersects = S25Util.array.hasIntersection(values.map(Number), dataAndType.value as number[]);
                if (rule.operator === "in") return intersects;
                if (rule.operator === "not in") return !intersects;
            case "number":
                return values.every((num) => {
                    return S25RuleTreeUtil.evaluateMathOperator(rule.operator, number, Number(num));
                });
            case "boolean":
                return values.every((b) => S25Util.bool.isTrue(b) === bool); //don't treat "No" as false
            case "text":
            case "textarea":
            case "multi":
                // INCLUDES DISCRETE
                const ruleTextValues = values.map(String);
                const dataTextValues = dataAndType.value as string[];
                if (rule.operator === "in" || rule.operator === "=") {
                    return dataTextValues.some((text) => ruleTextValues.some((str) => text === str));
                } else if (rule.operator === "contains") {
                    return dataTextValues.some((text) => ruleTextValues.some((str) => text.includes(str)));
                } else if (rule.operator === "not in") {
                    return !dataTextValues.some((text) => ruleTextValues.some((str) => text === str));
                }
                return false; // Should never reach this point
            case "date":
            case "datetime":
                return values.every((date) => S25RuleTreeUtil.evaluateMathOperator(rule.operator, text, date));
            case "time":
                return values.every((time) =>
                    S25RuleTreeUtil.evaluateMathOperator(rule.operator, text, S25Util.date.toS25ISOTimeStr(time)),
                );
            case "datetimeRange":
                const dateTimeRangeValues = dataAndType.value as { start: ISODateString; end: ISODateString }[];
                return values.every((dates) => {
                    // At least one range (occurrence) satisfies the condition at some point
                    return dateTimeRangeValues.some(({ start, end }) =>
                        evaluateRangeCondition(rule.operator, start, end, dates.split(",")),
                    );
                });
            case "timeRange":
                const timeRangeValues = dataAndType.value as { start: ISODateString; end: ISODateString }[];
                return values.every((times) => {
                    // At least one range (occurrence) satisfies the conditions at some point
                    const hours = times.split(",").map(Number);
                    return timeRangeValues.some(({ start, end }) =>
                        evaluateTimeRangeCondition(rule.operator, start, end, hours),
                    );
                });
            case "dow":
                const presentDows = dataAndType.value as Set<DowChar>;
                return values.every((dows) => {
                    return dows.split("").some((dow: DowChar) => presentDows.has(dow));
                });
            case "search":
                const searchData = dataAndType.value as Record<number, Record<number, boolean>>;
                const inSearch = values.some((queryId) => Object.values(searchData[Number(queryId)]).some((b) => b));
                if (rule.operator === "in" && inSearch) return true;
                if (rule.operator === "not in" && !inSearch) return true;
                return false;
            case "checkbox":
                if (bool === undefined) return false; // No affirmation visible
                return values.some((b) => S25Util.bool.isTrue(b) === bool);
        }
    }

    export function getValue(data: RuleData<RuleItem>, asyncData?: AsyncRuleData) {
        const sourceItemTypeId = parseInt(data.rule.sourceItem.itemTypeId as any) as Rules.TypeId;
        const type = Rules.typeIdToType[sourceItemTypeId]?.valueType.type;
        switch (sourceItemTypeId) {
            case Rules.type.ExpectedHeadcount.id:
                return { value: data.expectedHeadcount, type };
            case Rules.type.RegisteredHeadcount.id:
                return { value: data.registeredHeadcount, type };
            case Rules.type.Organization.id:
                return { value: [data.primaryOrg.itemId], type };
            case Rules.type.Location.id:
                return { value: data.locations.map((loc) => loc.itemId), type };
            case Rules.type.LocationLayout.id:
                return { value: data.locationLayouts.map((item) => item.itemId), type };
            case Rules.type.Resource.id:
                return { value: data.resources.map((item) => item.itemId), type };
            case Rules.type.LocationSearch.id:
                return { value: asyncData.search[Item.Ids.Location], type };
            case Rules.type.ResourceSearch.id:
                return { value: asyncData.search[Item.Ids.Resource], type };
            case Rules.type.OrganizationSearch.id:
                return { value: asyncData.search[Item.Ids.Organization], type };
            case Rules.type.EventType.id:
                return { value: [data.eventTypeId], type: type };
            case Rules.type.SecurityGroup.id:
                return { value: [data.securityGroupId], type };
            case Rules.type.EventCategory.id:
                return { value: data.eventCategories.map((item) => item.itemId), type };
            case Rules.type.Requirement.id:
                return { value: data.requirements.map((item) => item.itemId), type };
            case Rules.type.CalendarRequirement.id:
                return { value: data.calendarRequirements.map((item) => item.itemId), type };
            case Rules.type.CustomAttribute.id:
                const attribute = data.custAttrs?.find(
                    (attribute) => attribute.custAttrId === data.rule.sourceItem.itemId,
                );
                if (!attribute) return;
                return {
                    value: getAttributeValue(attribute),
                    type: attribute.multi_val
                        ? "multi"
                        : Rules.attributeValueType[attribute.itemTypeId as Rules.AttributeType].type,
                };
            case Rules.type.Date.id:
                const additionalTimeIdDate = data.rule.sourceItem.itemId as Rules.AdditionalTimeId;
                return {
                    value: data.occurrences.map((occ) => ({
                        start: S25Util.date.toS25ISODateTimeStr(
                            getDateWithAdditionalTime(additionalTimeIdDate, occ, "start"),
                        ),
                        end: S25Util.date.toS25ISODateTimeStr(
                            getDateWithAdditionalTime(additionalTimeIdDate, occ, "end"),
                        ),
                    })),
                    type: "datetimeRange",
                };
            case Rules.type.TimeOfDay.id:
                const additionalTimeIdTime = data.rule.sourceItem.itemId as Rules.AdditionalTimeId;
                return {
                    value: data.occurrences.map((occ) => ({
                        start: S25Util.date.toS25ISODateTimeStr(
                            getDateWithAdditionalTime(additionalTimeIdTime, occ, "start"),
                        ),
                        end: S25Util.date.toS25ISODateTimeStr(
                            getDateWithAdditionalTime(additionalTimeIdTime, occ, "end"),
                        ),
                    })),
                    type: "timeRange",
                };
            case Rules.type.DayOfWeek.id:
                const additionalTimeIdDow = data.rule.sourceItem.itemId as Rules.AdditionalTimeId;
                const dows: Set<DowChar> = new Set();
                for (let occ of data.occurrences) {
                    const start = getDateWithAdditionalTime(additionalTimeIdDow, occ, "start");
                    const end = getDateWithAdditionalTime(additionalTimeIdDow, occ, "end");
                    S25Util.date.getDows(start, end, dows);
                }
                return { value: dows, type: "dow" };
            case Rules.type.EventState.id:
                return { value: [data.eventState], type };
            case Rules.type.Affirmation.id:
                return { value: data.affirmation, type };
            default:
                return;
        }
    }

    export function getDateWithAdditionalTime(
        type: Rules.AdditionalTimeId,
        occ: RuleDataOccurrence,
        startOrEnd: "start" | "end",
    ) {
        const { setupMinutes, preMinutes, postMinutes, takedownMinutes } = occ.additionalTime;
        const date = startOrEnd === "start" ? occ.start : occ.end;
        const prePost = startOrEnd === "start" ? -preMinutes : postMinutes;
        const setupTakedown = startOrEnd === "start" ? -setupMinutes : takedownMinutes;
        switch (type) {
            case Rules.additionalTime.None.itemId:
                return date;
            case Rules.additionalTime.PrePost.itemId:
                return S25Util.date.addMinutes(date, prePost);
            case Rules.additionalTime.SetupTakedown.itemId:
                return S25Util.date.addMinutes(date, prePost + setupTakedown);
        }
    }

    export function getAttributeValue(attribute: Attribute) {
        switch (attribute.itemTypeId) {
            case CustomAttribute.type.Time:
                return S25Util.date.toS25ISOTimeStr(attribute.data);
            case CustomAttribute.type.Date:
                return S25Util.date.toS25ISODateStr(attribute.data);
            case CustomAttribute.type.Datetime:
                return S25Util.date.toS25ISODateTimeStr(attribute.data);
            case CustomAttribute.type.Organization:
            case CustomAttribute.type.Location:
            case CustomAttribute.type.Resource:
                return [attribute.itemId];
            case CustomAttribute.type.Integer:
            case CustomAttribute.type.Float:
                return Number(attribute.data);
            case CustomAttribute.type.Boolean:
                return S25Util.isUndefined(attribute.data) ? undefined : !!attribute.data; //preserve undefined so we don't treat No as false
            case CustomAttribute.type.String:
            case CustomAttribute.type.LargeText:
            case CustomAttribute.type.FileReference:
                if (attribute.multi_val && attribute.data) {
                    return S25Util.array
                        .forceArray(JSON.parse(attribute.data as string))
                        .map((item: { item: string }) => item.item || "");
                }
                return [attribute.data || ""]; // Default text based attributes to empty string if undefined
            default:
                return attribute.data;
        }
    }

    export function evaluateRangeCondition<T extends string | number>(
        operator: Rules.Operator,
        start: T,
        end: T,
        date: T[],
    ) {
        const [date1, date2] = date;
        switch (operator) {
            case "between":
                // The range (occurrence) is at some point overlapping the between range
                return date2 >= start && date1 <= end;
            case "=":
                // The range (occurrence) is at some point equal to the date
                return start <= date1 && date1 <= end;
            case "<":
                // The range (occurrence) is at some point less than the date
                return start < date1;
            case "<=":
                // The range (occurrence) is at some point less than or equal to the date
                return start <= date1;
            case ">=":
                // The range (occurrence) is at some point greater than or equal to the date
                return end >= date1;
            case ">":
                // The range (occurrence) is at some point greater than the date
                return end > date1;
        }
    }

    export function evaluateTimeRangeCondition(
        operator: Rules.Operator,
        start: ISODateString,
        end: ISODateString,
        hours: number[],
    ) {
        const startHour = S25Util.date.dateToHours(start);
        const diff = S25Util.date.diffHours(start, end);
        if (diff >= 24) return true; // Spans a whole day, so any condition would be met

        const satisfied = evaluateRangeCondition(operator, startHour, startHour + diff, hours);
        if (satisfied) return true;

        if (startHour + diff > 24) {
            // Spans midnight, also check morning hours
            return evaluateRangeCondition(operator, 0, (startHour + diff) % 24, hours);
        }

        return false;
    }
}

type Value = {
    itemId: number | string;
    itemName: string;
    initEmpty: boolean;
    value: string | number;
};

type Attribute = CustomAttribute.Attribute & {
    custAttrId: number;
    itemName: string | number;
    itemLabel: string;
    initEmpty: boolean;
    putAction: (itemId: number, attributeId: number, itemTypeId: number, newValue: Value["value"]) => Promise<void>;
    data?: string | number;
    multi_val: NumericalBoolean;
};

type RuleData<Rule extends RuleItems | RuleItem = RuleItems | RuleItem> = {
    rule: Rule;
    locations: ItemId[];
    resources: ItemId[];
    primaryOrg: ItemId;
    custAttrs: Attribute[];
    eventTypeId: number;
    securityGroupId: number;
    eventCategories: ItemId[];
    requirements: ItemId[];
    calendarRequirements: ItemId[];
    expectedHeadcount: number;
    registeredHeadcount: number;
    occurrences: RuleDataOccurrence[];
    locationLayouts: ItemId[];
    eventStateOriginal: number;
    eventState: number;
    affirmation: boolean;
};

export type AsyncRuleData = {
    search: Record<number, Record<number, Record<number, boolean>>>; // Item type -> queryId -> itemId
};

type RuleDataOccurrence = {
    start: Date;
    end: Date;
    additionalTime: { setupMinutes: number; preMinutes: number; postMinutes: number; takedownMinutes: number };
};

type ItemId = { itemId: number };
