//@author: devin

import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { HelpTopic, S25Help } from "./s25.help.service";
import { TypeManagerDecorator } from "../../main/type.map.service";

@TypeManagerDecorator("s25-help-link")
@Component({
    selector: "s25-help-link",
    template: `
        <div s25Help [helpTopic]="helpTopic" class="ngInlineBlock" aria-label="Help" role="button" tabindex="0">
            <ng-content></ng-content>
        </div>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25HelpComponent implements OnInit {
    @Input() helpTopic: HelpTopic;

    constructor(private elementRef: ElementRef) {}

    ngOnInit() {
        this.elementRef.nativeElement.angBridge = this;
    }
}
